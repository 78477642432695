import _ from 'lodash'
import { getField, updateField } from 'vuex-map-fields'
import requests from '../requests'
import {
  ORGANIZATION_URL,
  ORGANIZATION_SETTINGS_URL,
  WHATSAPP_URL,
} from '@/assets/variables/endpoints'
import channels from '~/utils/channel.js'
import { handleHideSubmenuButton } from '@/utils/general.js'

export const state = () => ({
  dataTable: [],
  ids: '',
  organizationLists: [],
  auto_resolve: '',
  organizationDetail: {
    avatar: '',
    name: '',
    phone: '',
    email: '',
    settings: {
      waba_id: '',
    },
  },
  feature_flag: {},
  limit_ongoing_chat: {},
  app_type: null,
  permissions: {},
  companySize: 0,
  is_mekari_smart_assist_active: false,
  template_pacing: false,
  userManagement: {},
})

export const getters = {
  getField,
  getSmartAssistInfo(state) {
    return state.is_mekari_smart_assist_active
  },
  getPermissions(state) {
    return state.permissions
  },
}

export const actions = {
  getOrganizations({ commit, state }, params) {
    if (params.offset === 1) params.is_counted = true
    commit(
      'layouts/UPDATE_CONTENTLOADING',
      { type: 'table', status: true },
      { root: true }
    )
    requests.whatsapp
      .getService(ORGANIZATION_URL, params, {
        Authorization: this.$auth.getToken('hub'),
      })
      .subscribe(
        (res) => {
          if (params.offset === 1)
            commit('layouts/SET_TOTAL_COUNT', res.meta.pagination.total, {
              root: true,
            })
          commit('layouts/SET_META', res.meta, { root: true })
          commit('SET_DATATABLE', res.data)
          commit(
            'layouts/UPDATE_CONTENTLOADING',
            { type: 'table', status: false },
            { root: true }
          )
        },
        (err) => {
          commit(
            'layouts/UPDATE_NOTIFICATION',
            {
              display: true,
              type: 'failed',
              message: 'Error!',
              item: err.error.messages[0],
              callback: {
                text: 'OK',
                method: 'closeNotification',
              },
            },
            { root: true }
          )
          commit(
            'layouts/UPDATE_CONTENTLOADING',
            { type: 'table', status: false },
            { root: true }
          )
        }
      )
  },
  getOrganizationLists({ commit, state }, params) {
    return new Promise((resolve, reject) => {
      commit(
        'layouts/UPDATE_CONTENTLOADING',
        { type: 'table', status: true },
        { root: true }
      )
      requests.whatsapp
        .getService(ORGANIZATION_URL, params, {
          Authorization: this.$auth.getToken('hub'),
        })
        .subscribe(
          (res) => {
            commit('UPDATE_ORGANIZATIONLIST', res.data)
            commit(
              'layouts/UPDATE_CONTENTLOADING',
              { type: 'table', status: false },
              { root: true }
            )
            resolve(res.meta.pagination)
          },
          (err) => {
            commit(
              'layouts/UPDATE_NOTIFICATION',
              {
                display: true,
                type: 'failed',
                message: 'Error!',
                item: err.error.messages[0],
                callback: {
                  text: 'OK',
                  method: 'closeNotification',
                },
              },
              { root: true }
            )
            commit(
              'layouts/UPDATE_CONTENTLOADING',
              { type: 'table', status: false },
              { root: true }
            )
            reject(err)
          }
        )
    })
  },
  getOrganizationListsAdmin({ commit }) {
    commit(
      'layouts/UPDATE_CONTENTLOADING',
      { type: 'table', status: true },
      { root: true }
    )
    requests.whatsapp
      .getService(
        `${ORGANIZATION_SETTINGS_URL}/me`,
        {},
        { Authorization: this.$auth.getToken('hub') }
      )
      .subscribe(
        (res) => {
          commit('SET_AUTO_RESOLVE', res.data.auto_resolve)
          commit('UPDATE_ORGANIZATIONLIST', [res.data])
          commit(
            'layouts/UPDATE_CONTENTLOADING',
            { type: 'table', status: false },
            { root: true }
          )
        },
        (err) => {
          commit(
            'layouts/UPDATE_NOTIFICATION',
            {
              display: true,
              type: 'failed',
              message: 'Error!',
              item: err.error.messages[0],
              callback: {
                text: 'OK',
                method: 'closeNotification',
              },
            },
            { root: true }
          )
          commit(
            'layouts/UPDATE_CONTENTLOADING',
            { type: 'table', status: false },
            { root: true }
          )
        }
      )
  },
  createOrganization({ commit, state, dispatch }, params) {
    commit('layouts/UPDATE_LOADINGBAR', true, { root: true })
    requests.whatsapp
      .postService(ORGANIZATION_URL, params, {
        Authorization: this.$auth.getToken('hub'),
      })
      .subscribe(
        (res) => {
          commit('layouts/UPDATE_LOADINGBAR', false, { root: true })
          commit(
            'layouts/UPDATE_NOTIFICATION',
            {
              display: true,
              type: 'success',
              message: 'Successfully Created!',
              item: '',
              callback: {
                text: 'OK',
                method: 'refreshOrganizations',
              },
            },
            { root: true }
          )
        },
        (err) => {
          commit(
            'layouts/UPDATE_NOTIFICATION',
            {
              display: true,
              type: 'failed',
              message: 'Error!',
              item: err.error.messages[0],
              callback: {
                text: 'OK',
                method: 'closeNotification',
              },
            },
            { root: true }
          )
          commit('layouts/UPDATE_LOADINGBAR', false, { root: true })
        }
      )
  },
  updateOrganization({ commit, state }, params) {
    commit('layouts/UPDATE_LOADINGBAR', true, { root: true })
    const myHeaders = new Headers()
    myHeaders.append('Authorization', this.$auth.getToken('hub'))

    const formdata = new FormData()
    formdata.append('id', state.organizationDetail.id)
    formdata.append('name', state.organizationDetail.name)
    formdata.append('email', state.organizationDetail.email)
    formdata.append('phone', state.organizationDetail.phone)
    if (params.editedAvatar)
      formdata.append('avatar', state.organizationDetail.avatar)
    if (state.organizationDetail.settings.waba_id)
      formdata.append('waba_id', state.organizationDetail.settings.waba_id)
    if (state.organizationDetail.settings.user_uniq_id)
      formdata.append(
        'user_uniq_id',
        state.organizationDetail.settings.user_uniq_id
      )
    if (state.organizationDetail.settings.organization_uniq_id)
      formdata.append(
        'organization_uniq_id',
        state.organizationDetail.settings.organization_uniq_id
      )

    const requestOptions = {
      method: 'PUT',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow',
    }

    fetch(`${ORGANIZATION_URL}/${params.id}`, requestOptions)
      .then((data) => data.json())
      .then((res) => {
        if (res.status === 'success') {
          commit('layouts/UPDATE_LOADINGBAR', false, { root: true })
          commit(
            'layouts/DISPLAY_TOAST',
            { message: 'User Succesfully Updated!' },
            { root: true }
          )
        } else {
          commit(
            'layouts/UPDATE_NOTIFICATION',
            {
              display: true,
              type: 'failed',
              message: 'Error!',
              item: res.error.messages[0] || '',
              callback: {
                text: 'OK',
                method: 'closeNotification',
              },
            },
            { root: true }
          )
          commit('layouts/UPDATE_LOADINGBAR', false, { root: true })
        }
      })
      .catch((err) => {
        commit(
          'layouts/UPDATE_NOTIFICATION',
          {
            display: true,
            type: 'failed',
            message: 'Error!',
            item: 'code: ' + err,
            callback: {
              text: 'OK',
              method: 'closeNotification',
            },
          },
          { root: true }
        )
        commit('layouts/UPDATE_LOADINGBAR', false, { root: true })
      })
  },
  getOrganizationDetail({ commit, state }, params) {
    commit(
      'layouts/UPDATE_CONTENTLOADING',
      { type: 'content', status: true },
      { root: true }
    )
    requests.whatsapp
      .getService(
        `${ORGANIZATION_URL}/${state.ids}`,
        {},
        { Authorization: this.$auth.getToken('hub') }
      )
      .subscribe(
        (res) => {
          commit('UPDATE_DETAIL', res.data)
          commit(
            'layouts/UPDATE_CONTENTLOADING',
            { type: 'content', status: false },
            { root: true }
          )
          commit('UPDATE_AVATAR', res.data.avatar.large.url)
        },
        (err) => {
          commit(
            'layouts/UPDATE_NOTIFICATION',
            {
              display: true,
              type: 'failed',
              message: 'Error!',
              item: err.error.messages[0],
              callback: {
                text: 'OK',
                method: 'closeNotification',
              },
            },
            { root: true }
          )
          commit(
            'layouts/UPDATE_CONTENTLOADING',
            { type: 'content', status: false },
            { root: true }
          )
        }
      )
  },
  getOrganizationSettings({ commit, state, dispatch }, params) {
    const bottommenuList = params.list.bottommenu ? params.list.bottommenu : {}
    let menuList = params.list.menu ? params.list.menu : {}
    let submenuList = params.list.submenu ? params.list.submenu : {}
    const submenuTabList = params.list.submenutab ? params.list.submenutab : {}
    const menuToDelete = []
    const submenuToDelete = []
    const submenuTabToDelete = []
    return new Promise((resolve, reject) => {
      if (this.$auth.user.role !== 'super_admin') {
        requests.whatsapp
          .getService(
            `${ORGANIZATION_SETTINGS_URL}/settings`,
            {},
            { Authorization: this.$auth.getToken('hub') }
          )
          .subscribe(
            async (res) => {
              commit('UPDATE_PERMISSIONS', {
                name: 'agent_idle_rule_assignment',
                value: res?.data?.agent_idle_rule_assignment ?? false,
              })
              commit(
                'SET_MEKARI_SMART_ASSIST_STATUS',
                res?.data?.is_mekari_smart_assist_active ?? false
              )
              commit('SET_FEATURE_FLAG', res?.data ?? {})
              commit('SET_AGENT_WORKLOAD', res?.data?.limit_ongoing_chat ?? {})
              commit(
                'SET_TEMPLATE_PACING_STATUS',
                res?.data?.template_pacing ?? false
              )
              commit(
                'contact_object/contact/UPDATE_CONTACT_LIST_UPLOAD_LIMIT',
                res.data.contact_list_upload,
                { root: true }
              )
              commit('UPDATE_PERMISSIONS', {
                name: 'igComment',
                value: res?.data?.ig_comment ?? false,
              })
              commit('UPDATE_PERMISSIONS', {
                name: 'waCloud',
                value: res?.data?.wa_cloud ?? false,
              })
              commit('UPDATE_PERMISSIONS', {
                name: 'exportChat',
                value: res?.data?.download_chat_history ?? false,
              })
              commit('UPDATE_PERMISSIONS', {
                name: 'exportChatReplica',
                value: res?.data?.download_chat_history_replica ?? false,
              })
              commit('UPDATE_PERMISSIONS', {
                name: 'downloadConversation',
                value: res?.data?.download_conversation ?? false,
              })
              commit('UPDATE_PERMISSIONS', {
                name: 'dd_histogram',
                value: res?.data?.dd_histogram ?? false,
              })
              commit('UPDATE_PERMISSIONS', {
                name: 'dd_histogram_sample_rate',
                value: res?.data?.dd_histogram_sample_rate ?? 20,
              })
              commit('UPDATE_PERMISSIONS', {
                name: 'new_room_ordering_logic',
                value: res?.data?.new_room_ordering_logic ?? false,
              })
              commit('UPDATE_PERMISSIONS', {
                name: 'new_survey_flow',
                value: res?.data?.new_survey_flow ?? false,
              })
              commit('UPDATE_PERMISSIONS', {
                name: 'customer_sentiment',
                value: res?.data?.conversation_sentiment ?? false,
              })
              commit('UPDATE_PERMISSIONS', {
                name: 'chatbot_tone_of_voice',
                value: res?.data?.chatbot_tone_of_voice ?? false,
              })
              commit('UPDATE_PERMISSIONS', {
                name: 'chatbot_score_card',
                value: res?.data?.chatbot_score_card ?? false,
              })
              commit('UPDATE_PERMISSIONS', {
                name: 'chatbot_conversation_summary',
                value: res?.data?.chatbot_conversation_summary ?? false,
              })
              commit('UPDATE_PERMISSIONS', {
                name: 'is_wa_group_active',
                value: res?.data?.is_wa_group_active ?? false,
              })
              commit('UPDATE_PERMISSIONS', {
                name: 'integration_with_shopee_chat_enabled',
                value: res?.data?.integration_with_shopee_chat_enabled ?? false,
              })
              commit('UPDATE_PERMISSIONS', {
                name: 'integration_with_tokopedia_chat_enabled',
                value:
                  res?.data?.integration_with_tokopedia_chat_enabled ?? false,
              })
              commit('UPDATE_PERMISSIONS', {
                name: 'tickets_by_contact_active',
                value: res?.data?.tickets_by_contact_active ?? false,
              })
              commit('UPDATE_PERMISSIONS', {
                name: 'broadcast_log_histories',
                value: res?.data?.broadcast_log_histories ?? true,
              })
              commit('UPDATE_PERMISSIONS', {
                name: 'qontak_channel_v3',
                value: res?.data?.qontak_channel_v3 ?? false,
              })
              commit('UPDATE_PERMISSIONS', {
                name: 'wa_coexistence',
                value: res?.data?.wa_coexistence ?? false,
              })
              commit('UPDATE_PERMISSIONS', {
                name: 'create_message_template_otp',
                value: res?.data?.create_message_template_otp ?? false,
              })
              commit(
                'chat/UPDATE_AUTO_RESPONDER',
                {
                  during_office: res.data.auto_responder_in_office_hours,
                  outside_office: res.data.auto_responder_out_office_hours,
                },
                { root: true }
              )
              commit(
                'agent/UPDATE_AGENT_ALLOCATION',
                {
                  agentTakeover: res.data.agent_can_takeover,
                  agentCanAssign: res.data.agent_can_assign_agent,
                  customAllocation: res.data.custom_agent_allocation.enabled,
                  autoAllocation: res.data.auto_assign_agent,
                  webhookURL:
                    res.data.custom_agent_allocation.webhooks.length > 0
                      ? res.data.custom_agent_allocation.webhooks[0].url
                      : '',
                },
                { root: true }
              )
              commit(
                'agent/UPDATE_AGENT_CAN_BROADCAST',
                res.data.agent_can_send_broadcast,
                { root: true }
              )
              commit(
                'agent/UPDATE_AGENT_CHAT_LIMIT',
                {
                  ...res.data.limit_ongoing_chat,
                  max: res.data.limit_ongoing_chat.enabled
                    ? res.data.limit_ongoing_chat.max
                    : '',
                },
                { root: true }
              )
              commit(
                'contact_object/contact/SET_MERGE_DEMERGE_FLAG',
                res.data.is_merge_unmerge_contact_disabled,
                { root: true }
              )

              commit('UPDATE_APP_TYPE', res.data.app_type)
              commit(
                'template/UPDATE_NEW_FB_PRICING_USAGE',
                res.data.new_fb_pricing,
                { root: true }
              )

              commit('layouts/UPDATE_APP_CHAT_FLAG', res.data.app_chat, {
                root: true,
              })

              // Check user privilization

              if (this.$auth.user.role === 'bot') {
                params.list = {}
                params.list.homeUrl = { url: 'unprivileged' }
              }

              /**
               * NOTE: app_type validation
               */
              if (res.data.app_type === 'full') {
                commit(
                  'report/UPDATE_SUBMENUS_REPORT',
                  { status: true, i: 0 },
                  { root: true }
                )
                commit(
                  'report/UPDATE_SUBMENUS_REPORT',
                  { status: true, i: 1 },
                  { root: true }
                )
                commit(
                  'report/UPDATE_SUBMENUS_REPORT',
                  { status: true, i: 2 },
                  { root: true }
                )
                commit(
                  'report/UPDATE_SUBMENUS_REPORT',
                  { status: true, i: 3 },
                  { root: true }
                )
                commit(
                  'report/UPDATE_SUBMENUS_REPORT',
                  { status: true, i: 4 },
                  { root: true }
                )
                commit(
                  'report/UPDATE_SUBMENUS_REPORT',
                  { status: true, i: 5 },
                  { root: true }
                )

                /**
                 * NOTE: add inbox menu
                 */
                const newMenuList = {}
                const newSubmenuList = {}
                newMenuList.inbox = menuList.inbox
                newSubmenuList.inbox = submenuList.inbox

                if (this.$auth.user.role === 'agent') {
                  dispatch('layouts/getConfiguration', {}, { root: true }).then(
                    (resAppConfig) => {
                      if (resAppConfig?.sla_dashboard_new_realtime) {
                        newMenuList.dashboard = {
                          title: 'Dashboard',
                          slug: 'dashboard',
                          status: true,
                          icon: 'dashboard',
                          redirect: 'dashboard',
                          category: 0,
                        }
                      }
                    }
                  )
                  if (res.data.agent_can_send_broadcast) {
                    newMenuList.whatsapp = {
                      title: 'Broadcast',
                      slug: 'whatsapp',
                      status: true,
                      icon: 'broadcast',
                      redirect: 'contacts',
                      category: 1,
                    }

                    newSubmenuList.contacts = {
                      title: 'Recipient',
                      slug: 'contacts',
                      status: true,
                      parent: 'whatsapp',
                      icon: 'table-view-list',
                    }

                    newSubmenuList.templates = {
                      title: 'Template message ',
                      slug: 'templates',
                      status: true,
                      parent: 'whatsapp',
                      icon: 'draft',
                    }

                    newSubmenuList.hsm = {
                      title: 'HSM Template',
                      slug: 'hsm',
                      status: true,
                      parent: 'whatsapp',
                      icon: 'las la-business-time',
                    }

                    newSubmenuList.campaigns = {
                      title: 'Campaign',
                      slug: 'campaigns',
                      status: true,
                      parent: 'whatsapp',
                      icon: 'broadcast',
                    }
                  }
                  menuList = newMenuList
                  submenuList = newSubmenuList
                } else {
                  params.list.homeUrl = { url: 'inbox' }
                }
              }
              if (res.data.app_type === 'outbound') {
                menuToDelete.push('inbox')
                menuToDelete.push('contacts')
                menuToDelete.push('chatbot')
                menuToDelete.push('dashboard')
                submenuToDelete.push('contact_reports')
                if (
                  this.$auth.user.role === 'admin' ||
                  this.$auth.user.role === 'supervisor'
                )
                  menuList.integrations.slug = 'integrations/whatsapp'
                if (
                  this.$auth.user.role !== 'super_admin' &&
                  this.$auth.user.role !== 'admin'
                )
                  menuToDelete.push('settings')
                submenuToDelete.push('inbox')
                Object.keys(submenuList).forEach((submenu) => {
                  if (
                    submenuList[submenu].parent === 'integrations' &&
                    submenuList[submenu].slug !== 'whatsapp'
                  )
                    submenuToDelete.push(submenuList[submenu].slug)
                  if (
                    submenuList[submenu].parent === 'reports' &&
                    submenuList[submenu].slug !== 'broadcast'
                  )
                    submenuToDelete.push(submenuList[submenu].slug)
                  if (
                    this.$auth.user.role !== 'super_admin' &&
                    this.$auth.user.role !== 'admin' &&
                    submenuList[submenu].parent === 'settings'
                  )
                    submenuTabToDelete.push(submenuList[submenu].slug)

                  if (
                    this.$auth.user.role === 'admin' &&
                    submenuList[submenu].parent === 'settings' &&
                    submenuList[submenu].slug !== 'token'
                  ) {
                    menuList.settings.slug = 'settings/token'
                    submenuToDelete.push(submenuList[submenu].slug)
                    submenuTabToDelete.push(submenuList[submenu].slug)
                  }
                })
                Object.keys(submenuTabList).forEach((submenu) => {
                  if (submenuTabList[submenu].slug !== 'campaign_reports')
                    submenuTabToDelete.push(submenuTabList[submenu].slug)
                })
                commit(
                  'report/UPDATE_SUBMENUS_REPORT',
                  { status: false, i: 0 },
                  { root: true }
                )
                commit(
                  'report/UPDATE_SUBMENUS_REPORT',
                  { status: false, i: 1 },
                  { root: true }
                )
                commit(
                  'report/UPDATE_SUBMENUS_REPORT',
                  { status: true, i: 2 },
                  { root: true }
                )
                commit(
                  'report/UPDATE_SUBMENUS_REPORT',
                  { status: false, i: 3 },
                  { root: true }
                )
                commit(
                  'report/UPDATE_SUBMENUS_REPORT',
                  { status: false, i: 4 },
                  { root: true }
                )
                commit(
                  'report/UPDATE_SUBMENUS_REPORT',
                  { status: false, i: 5 },
                  { root: true }
                )

                /**
                 * NOTE: add inbox menu
                 */
                const newMenuList = {}
                const newSubmenuList = {}
                newMenuList.inbox = menuList.inbox
                newSubmenuList.inbox = submenuList.inbox

                /**
                 * NOTE: role validation
                 */
                if (this.$auth.user.role !== 'agent') {
                  params.list.homeUrl = { url: 'whatsapp' }
                }
                if (this.$auth.user.role === 'agent') {
                  if (res.data.agent_can_send_broadcast) {
                    newMenuList.whatsapp = {
                      title: 'Broadcast',
                      slug: 'whatsapp',
                      status: true,
                      icon: 'broadcast',
                      redirect: 'contacts',
                      category: 1,
                    }

                    newSubmenuList.contacts = {
                      title: 'Recipient',
                      slug: 'contacts',
                      status: true,
                      parent: 'whatsapp',
                      icon: 'table-view-list',
                    }

                    newSubmenuList.templates = {
                      title: 'Template message ',
                      slug: 'templates',
                      status: true,
                      parent: 'whatsapp',
                      icon: 'draft',
                    }

                    newSubmenuList.hsm = {
                      title: 'HSM Template',
                      slug: 'hsm',
                      status: true,
                      parent: 'whatsapp',
                      icon: 'las la-business-time',
                    }

                    newSubmenuList.campaigns = {
                      title: 'Campaign',
                      slug: 'campaigns',
                      status: true,
                      parent: 'whatsapp',
                      icon: 'broadcast',
                    }
                    menuList = newMenuList
                    submenuList = newSubmenuList
                    params.list.homeUrl = { url: 'whatsapp' }
                  }
                  if (!res.data.agent_can_send_broadcast) {
                    params.list.homeUrl = { url: 'unprivileged' }
                  }
                }
              }
              if (res.data.app_type === 'inbox') {
                Object.keys(menuList).forEach((menu) => {
                  if (menuList[menu].slug !== 'inbox')
                    menuToDelete.push(menuList[menu].slug)
                })
                Object.keys(submenuList).forEach((submenu) => {
                  if (submenuList[submenu].slug !== 'inbox')
                    submenuToDelete.push(submenuList[submenu].slug)
                })
                Object.keys(submenuTabList).forEach((submenu) => {
                  submenuTabToDelete.push(submenuTabList[submenu].slug)
                })

                /**
                 * NOTE: add inbox menu
                 */
                const newMenuList = {}
                const newSubmenuList = {}
                newMenuList.inbox = menuList.inbox
                newSubmenuList.inbox = submenuList.inbox
              }

              let channelLists = channels

              // feature flag app chat / mobile chat integration
              if (!res.data.app_chat) {
                submenuToDelete.push('mobilechat')
                channelLists = channelLists.filter((channel) => {
                  return channel.code !== 'app_chat'
                })
                if (this.$auth.user.role !== 'agent') {
                  params.list.menu.integrations.redirect = 'tokopedia'
                }
              }

              if (res.data.google_my_business) {
                channelLists.push({
                  title: 'Google My Business',
                  slug: 'google_my_business',
                  status: true,
                  parent: 'integrations',
                  icon: 'chat',
                  code: 'google_my_business',
                  value: 'google_my_business',
                  label: 'Google My Business',
                })
              }

              if (
                state.permissions.integration_with_shopee_chat_enabled === false
              ) {
                const filterChannelLists = _.filter(
                  channelLists,
                  function (ch) {
                    return ch.code !== 'shopee'
                  }
                )

                // store channel lists to inbox store
                commit('inbox/SET_CHANNEL_LISTS', filterChannelLists, {
                  root: true,
                })
              } else {
                // store channel lists to inbox store
                commit('inbox/SET_CHANNEL_LISTS', channelLists, { root: true })
              }

              // feature flag export conversation
              if (
                !state.permissions.exportChat &&
                !state.permissions.downloadConversation
              ) {
                submenuToDelete.push('export')
              }

              // Delete menu based on privilization

              menuToDelete.forEach((menu) => {
                delete menuList[menu]
              })

              submenuToDelete.forEach((menu) => {
                delete submenuList[menu]
              })

              submenuTabToDelete.forEach((menu) => {
                delete submenuTabList[menu]
              })

              const resUserMe = await dispatch(
                'template/getCredential',
                {},
                { root: true }
              )

              let newSubmenuList = handleHideSubmenuButton(submenuList, {
                me: resUserMe.data,
                settings: res.data,
              })

              if (res.data.google_my_business) {
                const gbrSubmenu = {
                  title: 'Google My Business',
                  slug: 'google-my-business',
                  status: res.data.google_my_business,
                  parent: 'integrations',
                  icon: 'chat',
                  code: 'google-my-business',
                }
                newSubmenuList = {
                  google_my_business: gbrSubmenu,
                  ...newSubmenuList,
                }
              }

              submenuList = { ...newSubmenuList }

              commit(
                'preferences/FEATURE',
                {
                  ...params.list,
                  bottommenu: bottommenuList,
                  menu: menuList,
                  submenu: submenuList,
                  submenutab: submenuTabList,
                },
                { root: true }
              )

              commit('UPDATE_PERMISSIONS', {
                name: 'bulk_update_contact',
                value: res?.data?.bulk_update_contact ?? false,
              })

              commit('UPDATE_PERMISSIONS', {
                name: 'bulk_update_contacts',
                value: res?.data?.bulk_update_contacts ?? false,
              })

              commit('UPDATE_PERMISSIONS', {
                name: 'reporting_division_filter',
                value: res?.data?.reporting_division_filter ?? false,
              })

              commit('UPDATE_PERMISSIONS', {
                name: 'knowledge_base_active',
                value: res?.data?.knowledge_base_active ?? false,
              })

              commit('UPDATE_PERMISSIONS', {
                name: 'knowledge_base_url',
                value: res?.data?.knowledge_base_url ?? false,
              })

              commit('UPDATE_PERMISSIONS', {
                name: 'enable_inbox_sla',
                value: res?.data?.enable_inbox_sla ?? false,
              })

              commit('UPDATE_PERMISSIONS', {
                name: 'security_settings',
                value: res?.data?.security_settings ?? false,
              })

              commit(
                'conversation/UPDATE_SLA_INBOX_STATUS',
                res?.data?.enable_inbox_sla ?? false,
                { root: true }
              )

              commit(
                'inbox/UPDATE_SLA_INBOX_STATUS',
                res?.data?.enable_inbox_sla ?? false,
                { root: true }
              )

              commit(
                'inbox/SET_ORGANIZATION_INBOX_POOLING',
                res?.data?.inbox_pooling ?? null,
                { root: true }
              )

              commit('UPDATE_PERMISSIONS', {
                name: 'google_my_business',
                value: res?.data?.google_my_business ?? false,
              })

              commit('UPDATE_PERMISSIONS', {
                name: 'message_notification_ack',
                value: res?.data?.message_notification_ack ?? false,
              })

              resolve(true)
            },
            (err) => {
              if (this.$auth.user.role !== 'super_admin') this.$auth.logout()
              if (!params.route === 'sso-callback' && err.error.code === 401) {
                commit(
                  'layouts/UPDATE_NOTIFICATION',
                  {
                    display: true,
                    type: 'failed',
                    message: 'Error!',
                    item: err.error.messages[0],
                    callback: {
                      text: 'OK',
                      method: 'closeNotification',
                    },
                  },
                  { root: true }
                )
              }
              reject(err)
            }
          )
      } else {
        commit('preferences/FEATURE', { ...params.list }, { root: true })
        resolve(true)
      }
    })
  },
  syncTemplateData({ commit, state }, params) {
    return new Promise((resolve, reject) => {
      commit('UPDATE_SYNC_LOADING', { id: params.id, status: 'loading' })
      requests.whatsapp
        .postService(
          `${WHATSAPP_URL.template}/whatsapp/sync`,
          { organization_id: params.id },
          { Authorization: this.$auth.getToken('hub') }
        )
        .subscribe(
          (res) => {
            commit('UPDATE_SYNC_LOADING', {
              id: params.id,
              status: res.data.status,
            })
            setTimeout(() => {
              commit('UPDATE_SYNC_LOADING', {
                id: params.id,
                status: 'changed',
              })
            }, 2000)
            resolve(true)
          },
          (err) => {
            commit(
              'layouts/UPDATE_NOTIFICATION',
              {
                display: true,
                type: 'failed',
                message: 'Error!',
                item: err.error.messages[0],
                callback: {
                  text: 'OK',
                  method: 'closeNotification',
                },
              },
              { root: true }
            )
            commit('UPDATE_SYNC_LOADING', { id: params.id, status: false })
            reject(err)
          }
        )
    })
  },
  deleteOrganization({ commit, state, dispatch }, params) {
    commit('layouts/UPDATE_LOADINGBAR', true, { root: true })
    requests.whatsapp
      .deleteService(`${ORGANIZATION_URL}/${params.id}`, params, {
        Authorization: this.$auth.getToken('hub'),
      })
      .subscribe(
        (res) => {
          commit('layouts/UPDATE_LOADINGBAR', false, { root: true })
          commit(
            'layouts/UPDATE_NOTIFICATION',
            {
              display: true,
              type: 'success',
              message: 'Successfully Deleted!',
              item: '',
              callback: {
                text: 'OK',
                method: 'refreshOrganizations',
              },
            },
            { root: true }
          )
        },
        (err) => {
          commit(
            'layouts/UPDATE_NOTIFICATION',
            {
              display: true,
              type: 'failed',
              message: 'Error!',
              item: err.error.messages[0],
              callback: {
                text: 'OK',
                method: 'closeNotification',
              },
            },
            { root: true }
          )
          commit('layouts/UPDATE_LOADINGBAR', false, { root: true })
        }
      )
  },
  changeAppType({ commit, state }, params) {
    commit(
      'layouts/UPDATE_CONTENTLOADING',
      { type: 'content', status: true },
      { root: true }
    )
    requests.whatsapp
      .putService(`${ORGANIZATION_URL}/${params.id}/app_type`, params, {
        Authorization: this.$auth.getToken('hub'),
      })
      .subscribe(
        (res) => {
          commit(
            'layouts/UPDATE_CONTENTLOADING',
            { type: 'content', status: false },
            { root: true }
          )
        },
        () => {
          commit(
            'layouts/UPDATE_CONTENTLOADING',
            { status: false },
            { root: true }
          )
        }
      )
  },
  async updateAutoResolve({ commit, state }, params) {
    await commit('UPDATE_PERIOD')
    commit('layouts/UPDATE_CONTENTLOADING', { status: true }, { root: true })
    requests.whatsapp
      .putService(`${ORGANIZATION_SETTINGS_URL}/auto_resolve`, params, {
        Authorization: this.$auth.getToken('hub'),
      })
      .subscribe(
        (res) => {
          commit(
            'layouts/UPDATE_CONTENTLOADING',
            { status: false },
            { root: true }
          )
          commit('SET_AUTO_RESOLVE', res.data)
          if (res.data.is_active) {
            commit(
              'layouts/UPDATE_NOTIFICATION',
              {
                display: true,
                type: 'success',
                message: 'Successfully activate auto resolve',
                item: '',
                callback: {
                  text: 'OK',
                  method: 'closeNotification',
                },
              },
              { root: true }
            )
          } else {
            commit(
              'layouts/UPDATE_NOTIFICATION',
              {
                display: true,
                type: 'success',
                message: 'Successfully deactivate auto resolve',
                item: '',
                callback: {
                  text: 'OK',
                  method: 'closeNotification',
                },
              },
              { root: true }
            )
          }
        },
        (err) => {
          commit(
            'layouts/UPDATE_CONTENTLOADING',
            { status: false },
            { root: true }
          )
          commit(
            'layouts/UPDATE_NOTIFICATION',
            {
              display: true,
              type: 'failed',
              message: 'Error!',
              item: err.error.messages[0].active_model_errors.period[0],
              callback: {
                text: 'OK',
                method: 'closeNotification',
              },
            },
            { root: true }
          )
        }
      )
  },
  getWabaId({ commit, state }, params) {
    return new Promise((resolve) => {
      requests.whatsapp
        .getService(
          `${ORGANIZATION_SETTINGS_URL}/me`,
          {},
          { Authorization: this.$auth.getToken('hub') }
        )
        .subscribe((res) => {
          resolve(res.data.settings.waba_id)
        })
    })
  },
  getCompanySize({ commit }) {
    return new Promise((resolve) => {
      const headers = {
        Authorization: this.$auth.getToken('hub'),
      }
      this.$axios
        .get(`${ORGANIZATION_SETTINGS_URL}/metadata`, { headers })
        .then((res) => {
          commit('SET_COMPANY_SIZE', res.data.data.count_of_user)
          resolve(res.data)
        })
    })
  },
}

export const mutations = {
  SET_MEKARI_SMART_ASSIST_STATUS(state, payload) {
    state.is_mekari_smart_assist_active = payload
  },
  SET_FEATURE_FLAG(state, payload) {
    state.feature_flag = payload
  },
  SET_AGENT_WORKLOAD(state, payload) {
    state.limit_ongoing_chat = payload
  },
  SET_TEMPLATE_PACING_STATUS(state, payload) {
    state.template_pacing = payload
  },
  updateField,
  SET_DATATABLE(state, payload) {
    state.dataTable = payload
  },
  SET_AUTO_RESOLVE(state, payload) {
    const countHour = {
      hour: Math.floor(payload.period / 60),
      minutes: payload.period % 60,
    }
    state.auto_resolve = payload
    state.auto_resolve.count_hour = countHour
  },
  UPDATE_PERIOD_VALUE(state, { e, type }) {
    state.auto_resolve.count_hour[type] = Number(e.target.value)
  },
  UPDATE_ACTIVE_VALUE(state, e) {
    state.auto_resolve.is_active = e
  },
  UPDATE_PERIOD(state) {
    const period =
      state.auto_resolve.count_hour.hour * 60 +
      state.auto_resolve.count_hour.minutes
    state.auto_resolve.period = period
  },
  UPDATE_DETAIL(state, payload) {
    if (!payload.settings.waba_name) payload.settings.waba_name = ''
    state.organizationDetail = payload
  },
  SET_ORGANIZATIONLIST(state, payload) {
    state.organizationLists = payload
  },
  UPDATE_ORGANIZATIONLIST(state, payload) {
    state.organizationLists.push(...payload)
  },
  SET_ID(state, payload) {
    state.ids = payload
  },
  UPDATE_AVATAR(state, payload) {
    state.organizationDetail.avatar = payload
  },
  UPDATE_SYNC_LOADING(state, payload) {
    const index = _.findIndex(state.dataTable, { id: payload.id })
    state.dataTable[index].avatar.size = payload.status
  },
  UPDATE_APP_TYPE(state, payload) {
    state.app_type = payload
  },
  UPDATE_PERMISSIONS(state, payload) {
    state.permissions[payload.name] = payload.value
  },
  SET_COMPANY_SIZE(state, payload) {
    state.companySize = payload
  },
  UPDATE_NEW_USER_MANAGEMENT(state, payload) {
    state.userManagement = payload
  },
}
